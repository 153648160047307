<template>
    <div class="page flex-col">
        <header>
            <van-nav-bar
                    title="邀请码"
                    left-text=""
                    left-arrow
                    @click-left="onClickLeft"
            />
        </header>
        <div class="section_2 flex-col">
            <div class="box_3 flex-col"></div>
            <div class="box_4 flex-col">
                <input type="text" class="text_6" placeholder="请输入邀请码" v-model="fatherCode"/>
                <!--<span class="text_6">请输入邀请码</span>-->
                <div class="group_1 flex-col"></div>
                <span class="text_7">提示：本邀请码一旦输入，不可二次变更</span>
            </div>
            <div class="text-wrapper_2 flex-col" v-show="isShow" @click="saveFatherCode"><span class="text_8">确认</span>
            </div>
        </div>
    </div>
</template>
<script>
    import {saveFatherCode} from "@/api/user";

    export default {
        data() {
            return {
                fatherCode: "",
                isShow: false,
                saveFatherCodeParam: {}
            };
        },
        created() {
            const user = localStorage.getItem('userInfoDs')
            this.saveFatherCodeParam.account = JSON.parse(user).account
            const fatherCode = this.$route.query.fatherCode;
            if (fatherCode) {
                this.fatherCode = fatherCode;
            } else {
                this.isShow = true
            }
        },
        methods: {
            saveFatherCode() {
                this.saveFatherCodeParam.fatherCode = this.fatherCode
                saveFatherCode(this.saveFatherCodeParam).then(response => {
                    this.$model({
                        show: true,
                        title: "提示",
                        content: response.msg,
                        confirmButton: false,
                        cancelButton: true
                    });
                    this.$router.push('/wode')
                });
            },
            onClickLeft() {
                this.$router.push('/wode')
            },
        }
    };
</script>
<style scoped lang="css" src="./assets/index.rem.css"/>